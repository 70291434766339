/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import { getQueryParams } from '@domui-utils/query-params';
import Metadata from '@domui-utils/workflow/metadata';

const StatePage: React.FC = () => {
  const [stateData, setStateData] = useState<any>([]);
  const { countryCode } = getQueryParams();

  useEffect(() => {
    async function fetchStateData() {
      const wfService = new Metadata();
      const response = await wfService.getStateData(
        (countryCode as string) || 'IND'
      );
      setStateData(response);
    }
    fetchStateData();
  }, []);

  if (!stateData) {
    return <Loading />;
  }

  return (
    <div>
      {!!stateData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>State Page</h3>
          <ul>
            {stateData.map((item: any) => (
              <div key={item.id}>
                {item.id} - state PK : {item.statePk}
                {item.id} - code : {item.code}
                {item.id} - stateName : {item.stateName}
                {item.id} - countryCode : {item.countryCode}
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StatePage;
